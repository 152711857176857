import { Link, useI18next } from "gatsby-plugin-react-i18next"
import React from "react"
import { STEAM_URL } from "../utils"

export function Menubar() {
    return (
        <nav className="flex items-center flex-col">
            <MenuBg>
                <ul className="flex flex-row items-center justify-center text-fl-white">
                    <Item text="Frameland" url="/" icon="game" />
                    {STEAM_URL && (
                        <Item text="Steam ↗" url={STEAM_URL} isExternal={true} icon="crystal" />
                    )}
                    <Item text="Presskit" url="/presskit/" icon="book" />
                    <Item text="About" url="/about/" icon="about" />
                </ul>
            </MenuBg>
        </nav>
    )
}

function MenuBg({ children }) {
    return (
        <div className="w-full bg-fl-keyart text-center opacity-100 overflow-x-scroll">
            <span className="px-2 py-4 inline-block text-left">{children}</span>
        </div>
    )
}

function Item({ text, url, isExternal, icon }) {
    const iconPath = `/assets/icons/icon_${icon}.png`
    const linkClasses =
        "py-2 text-gray-300 hover:text-fl-turkis-light uppercase tracking-wider text-sm"
    return (
        <li className="px-0 py-2 whitespace-nowrap first-of-type:pl-2 last-of-type:pr-2" key={url}>
            <span className="flex items-center px-4 sm:px-3">
                {isExternal && (
                    <>
                        {icon && <img src={iconPath} className="mr-1" width={20} height={20} />}
                        <a href={url} target="_blank" rel="noreferrer" className={linkClasses}>
                            {text}
                        </a>
                    </>
                )}
                {!isExternal && (
                    <>
                        <img src={iconPath} className="mr-1" width={20} height={20} />
                        <Link to={url} className={linkClasses} activeClassName="menu-active">
                            {text}
                        </Link>
                    </>
                )}
            </span>
        </li>
    )
}
