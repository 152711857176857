import React from "react"
import { Head as DefaultHead } from "../head"
import { buttonDefaultStyle } from "../components/Buttons"
import { Footer } from "../components/Footer"
import { Main } from "../components/Main"
import { Menubar } from "../components/Menu"
import { Section } from "../components/Section"
import { ContentContainer } from "../components/Container"
import { SteamButton } from "../components/Buttons"
import { graphql } from "gatsby"

function About() {
    return (
        <Main>
            <Menubar />
            <Section bg="bg-fl-turkis-dark" color="text-fl-text">
                <ContentContainer>
                    <h1 className="text-4xl font-serif text-center text-fl-turkis-light mb-12">
                        About
                    </h1>
                    <Heading>
                        Team
                        <small className="block text-sm opacity-80">
                            A small indie team aiming high
                        </small>
                    </Heading>
                    <div className="bg-fl-turkis-dark bg-opacity-50 border-fl-turkis-dark border max-w-md mx-auto mt-5 border-b-4 rounded-lg">
                        <ul className="small:flex ml-4 sm:ml-0 flex-row justify-center mt-6 md:flex-row">
                            <Char
                                char="markus"
                                secondName="Langthaler"
                                link="mailto:markus.langthaler@gmx.net"
                                role={
                                    <>
                                        <span className="small:block">Programming</span>
                                        <span className="small:hidden">, </span>
                                        <span className="small:block">Game Design</span>
                                    </>
                                }
                            />
                            <Char
                                char="sebastian"
                                secondName="Höhnl"
                                link="https://x.com/frameland_basti"
                                role={
                                    <>
                                        <span className="small:block">Art</span>
                                        <span className="small:hidden">, </span>
                                        <span className="small:block">Map Design</span>
                                    </>
                                }
                            />
                            <Char
                                char="mario"
                                secondName="Dederichs"
                                link="https://blakeinc.de"
                                role={
                                    <>
                                        <span className="small:block">Narrative</span>
                                        <span className="small:hidden">, </span>
                                        <span className="small:block">Music</span>
                                    </>
                                }
                            />
                        </ul>
                        <P className="text-sm p-4 pt-2 mb-0 leading-5">
                            Frameland Games is a small game company based in Vienna, Austria. We
                            believe that good games don't have to be&nbsp;big. In 2016, as a small
                            team of three, we started working on a new project:
                            Our&nbsp;own&nbsp;take on the&nbsp;classical&nbsp;RPG!
                        </P>
                        <hr className="border-fl-black mb-2 -mt-2" />
                        <P className="text-sm px-4 pt-2 pb-0 mb-6 leading-6">
                            <b className="block">With additional help from:</b>
                            <CollabPartner
                                name="Laura Löffler"
                                role="Facesets, Keyart & more"
                                url="https://ko-fi.com/sabbi_tabbi"
                            />
                            <CollabPartner
                                name="Jason Perry"
                                role="Enemy design, Sprites & Tilesets"
                                url="http://finalbossblues.com"
                            />
                            <CollabPartner
                                name="Timo Schumann"
                                role="Lead Tester"
                                url="https://x.com/Timb0r?t=TdBjxZwi0iAlgSa8-04diQ&s=09"
                            />
                        </P>
                    </div>
                    <Heading>
                        History
                        <small className="block text-sm opacity-80">
                            A short history of the development of Frameland
                        </small>
                    </Heading>
                    <div className="max-w-md mx-auto">
                        <small className="block text-lg opacity-80 text-center mb-0 mt-6">
                            2016: Humble beginnings
                        </small>
                        <P className="mt-2">
                            Initial development started pretty slow. As it turns out, figuring out
                            all the parts of how an RPG works is not that easy: You need maps, npcs,
                            battles, a story, a main-menu, explosions and a lot more. Instead of
                            using an engine like RPG Maker to hit the ground running, Markus decided
                            to build everything from scratch, because this way "we have more control
                            later on".
                        </P>
                        <span className="block mt-4">
                            <Image
                                src="2016.png"
                                alt="First screens from 2016"
                                caption="A few of the first screens from 2016, including an early map editor (middle)"
                            />
                        </span>
                        <small className="block text-lg opacity-80 text-center mb-0 mt-6">
                            2017: First Demo 🕹️
                        </small>
                        <P className="">
                            After about a year in development, we premiered our first demo at the
                            Reversed Game Festival in Vienna, Austria. Seeing people sit down for 30
                            minutes and complete the full demo was a truly special moment for us 😊
                            <span className="block mt-4">
                                <Image
                                    src="reversed.jpg"
                                    alt="Reversed Game Festival, 2017"
                                    caption="Demoing the first time at Reversed Game Festival in Vienna, 2017"
                                />
                            </span>
                        </P>
                        <Subheading>2018: A new battle system ⚔</Subheading>
                        <P>
                            When watching players play at the festival, we realized something was
                            off with the battles! Based on the feedback we gathered, we did a
                            complete rewrite of the combat system to make it more engaging. We kept
                            the classic turn-based nature that allows you to plan ahead, but added a
                            unique timing mechanic that adds a bit of skill to each battle.
                        </P>
                        <span className="block mt-4">
                            <Image
                                src="battle_prototype.gif"
                                alt="Early prototype of the timed-hit battle system in action"
                                caption="Early prototype of the timed-hit battle system"
                            />
                        </span>
                        <Subheading>2019 & 2020: Finding Focus 🎯</Subheading>
                        <P>
                            An RPG is a big logistic undertaking. Until now we got away by just
                            throwing everything in Discord but by 2019 our lack of organization
                            started to show. Things got lost, we had different versions of the game
                            in our head and because a chat like Discord is linear, it creates an
                            ASAP culture which often also lacks context. The way we solved that is
                            using a wiki as a "source of truth" and switch to an async model of
                            communication. We also wrote an&nbsp;
                            <a
                                href="https://frameland.itch.io/a-binary-tale/devlog/209591/2020-review-finding-focus"
                                target="_blank"
                                rel="noreferrer"
                                className="underline"
                            >
                                in-depth article
                            </a>{" "}
                            about it.
                        </P>
                        <span className="block mt-4">
                            <Image
                                src="notion.png"
                                alt="Using Notion to organize the game's content"
                                caption="Using Notion to organize the game's content"
                                hasShadow={false}
                            />
                        </span>
                        <Subheading>2021: The Last Dungeon! 🗺️</Subheading>
                        <P>
                            With our newfound focus, we started working on the last dungeon of the
                            game, which would be quite different from your typtical RPG end-dungeon:
                            An office building! Many players have highlighted this as their favorite
                            part of the game, especially because it touches on themes like social
                            criticism, modern office culture, and other aspects of everyday life.
                        </P>
                        <span className="block mt-4">
                            <Image
                                src="office.png"
                                alt="An office: Frameland's final dungeon"
                                caption="Not your everyday office!"
                            />
                        </span>
                        <Subheading>2022 & 2023: Steampage & Trailer 🎬</Subheading>
                        <P>
                            Until now, few people knew about our game, so we set out to change that!
                            We made a website, a steampage and created a trailer. We got help from
                            the amazing artist{" "}
                            <a
                                href="https://ko-fi.com/sabbi_tabbi"
                                target="_blank"
                                rel="noreferrer"
                                className="inline-block underline underline-offset-2"
                            >
                                Sabbi Tabbi
                                <span className="mx-1 inline-block font-sans text-xs">↗</span>
                            </a>{" "}
                            who not only did portraits for our characters in game, but also created
                            our Keyart, which we used on the website & steampage. Because she
                            involved us in this process, you can also see how it came to be:
                        </P>
                        <span className="block mt-4">
                            <Image
                                src="keyart_timelapse.gif"
                                alt="Keyart Illustration Timelapse"
                                caption="Keyart Illustration Timelapse"
                            />
                        </span>
                        <P className="mt-4">
                            At the end of 2023, we believed our content was nearly
                            complete&nbsp;—&nbsp;about 90% done. However, as tradition goes, the
                            remaining 90% awaited us in 2024 😒
                        </P>
                        <Subheading>2024: Beta Testing & Launch 🚀</Subheading>
                        <P>
                            As we got closer to release, our todo lists got longer each week instead
                            of shorter, even though we worked more on the project than ever before
                            🧐
                            <span className="block mb-4 mt-2">
                                <Image
                                    src="launch.png"
                                    alt="Commit history of 2024 Frameland"
                                    caption="Number of commits/changes we made during the year. From July onwards we entered the infamouse crunchtime period!"
                                />
                            </span>
                            Early Sepember we started sending out a beta version, roughly 8 weeks
                            prior to launch. Thanks to our testers we were able to catch most bugs
                            before release. Finally, on October 30th 2024, after 8 years in
                            development, we released Frameland to the world 🚀 It's been a long
                            journey and we are very proud of what we have build. We hope you enjoy
                            it ☺️
                        </P>
                    </div>
                    <div className="pt-12 pb-8 max-w-md mx-auto">
                        <SteamButton />
                        <span className="text-center block mt-4 text-xs text-gray-400">
                            Free demo is available, if you want to try it first
                        </span>
                    </div>
                </ContentContainer>
            </Section>
            <Footer />
        </Main>
    )
}

function CollabPartner({ name, role, url }) {
    return (
        <span className="block sm:table-row">
            <a
                href={url}
                target="_blank"
                rel="noreferrer"
                className="inline-block underline underline-offset-2 sm:table-cell"
            >
                {name}
                <span className="ml-1 mr-3 inline-block font-sans text-xs">↗</span>
            </a>
            <span className="block sm:table-cell">{role}</span>
        </span>
    )
}

function Image({ src, alt, caption, hasShadow = true }) {
    const path = "/assets/history/" + src
    const classes = `cursor-pointer mb-3 block border-fl-turkis-dark/50 ${
        hasShadow ? "border-2" : ""
    }`
    return (
        <>
            <a href={path} target="_blank" rel="noreferrer" className={classes}>
                <img src={path} alt={alt} />
            </a>
            {caption && <span className="text-xs opacity-50 -mt-2 ml-1 block">{caption}</span>}
        </>
    )
}

function Heading({ children }) {
    return (
        <h2 className="text-xl mt-12 mb-3 text-center font-serif font-bold text-gray-100">
            {children}
        </h2>
    )
}

function Subheading({ children }) {
    return (
        <h3 className="block text-lg opacity-80 text-center mb-0 mt-6">
            <small className="block text-lg opacity-80 text-center mb-0 mt-6">{children}</small>
        </h3>
    )
}

function P({ children, className = "" }) {
    return <p className={"text-md mb-3 " + className}>{children}</p>
}

function Char({ char, role, secondName, link }) {
    return (
        <li key={char} className="flex items-center mr-2 small:mr-6 mb-4">
            <img
                src={`/assets/chars/${char}.png`}
                alt={`Developer ${char}`}
                className="inline-block"
            />
            <div className="ml-2">
                <a
                    href={link}
                    target="_blank"
                    rel="noreferrer"
                    className="inline-block underline underline-offset-2 uppercase text-xs tracking-wider"
                >
                    {char}
                    <br />
                    <span className="whitespace-nowrap">{secondName} ↗</span>
                </a>
                <p className="text-xs opacity-50">{role}</p>
            </div>
        </li>
    )
}

export function Head() {
    return (
        <DefaultHead
            title="Frameland: About"
            description="Frameland: About the team and development history."
        />
    )
}

export default About

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`
